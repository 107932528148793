.error-boundary-wrapper {
    display: flex;
    justify-content: center;
    background-color: transparent;
    color: #2B2B2B;
    height: 80%;
    align-items: center;
}
.error-boundary-container {
    width: 100%;
    height: fit-content;
}
.error-boundary-container > div {
    width: 100%;
    display: flex;
    justify-content: center;
}
.error-boundary-heading {
    text-align: center;
}

@media (max-width: 768px) {
    .error-boundary-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 25%;
    }
}
